<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
import Vue from "vue";

export default {
  page: {
    title: "Liste des dons",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Dons",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Dons Reçu",
          active: true,
        },
      ],
      donImage: null,
      donDes:[],
      donActif:[],
      totalRows: 0,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      filterOn: [],
      dons: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "donateur", sortable: true, label: "Nom & Prénoms" },
        { key: "titreDon", sortable: true, label: "Titre du dons" },
        { key: "montant", sortable: true, label: "Montant" },
        { key: "date", sortable: true },
      ],
      donsrow: null,
      startValue: 1,
      endValue: 500,
      totalValue: "0",
      userChargeLoader: false,
      newDons: [],
      recherche:""
    };
  },
  
  async mounted() {
    this.init();
  },

  methods: {

    goToUserDetail(id) {
      window.open(`/user/user_info/${id}`, '_blank','noreferrer');
    },
    async init() {
      const listeDons = await apiRequest("GET", "admin/don", undefined, false);

      if (listeDons && listeDons.data) {

       
        const donFiltered = listeDons.data.filter((data)=>data.user !== undefined && data.user.id !== null);

        const donFormatTable = donFiltered.map((don) => {
          return {
            id: don.user.id || "non défini",
            titreDon: don.action.titre,
            date: new Date(don.dateVirement).toLocaleString("fr-FR"),
            donateur: don.user!=null? don.user.personne.lastname + " " + don.user.personne.firstname:"",
            montant: don.fraisSocial,
          };
        });

        this.dons = donFormatTable;
        this.totalRows=this.donActif.length
      this.donDes=[];
            
      for(let i=0;i<this.dons.length;i++){
        if(this.dons[i].isActif===false){
          this.donDes.push(this.dons[i])
        }
        else{
          this.newDons.push(this.dons[i])
        }
          
      }
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async actif(page, limit) {
      //Chargement des données

      const listeWallet = await apiRequest(
        "GET",
        "admin/don?page=" + page + "&limit=" + limit,
        undefined,
        false
      );
      
     

      if (listeWallet && listeWallet.data) {
        //const WalletFiltered = listeWallet.data.filter((data)=>data.user !== undefined && data.user.id !== null);

        const donFormatTable =listeWallet.data.map((don) => {
          return {
            id: don.user.id,
            titreDon: don.action.titre,
            date: new Date(don.dateVirement).toLocaleString("fr-FR"),
            donateur:don.user!=null? don.user.personne.lastname + " " + don.user.personne.firstname:"",
            montant: don.fraisSocial,
          };
        });

        this.newDons = donFormatTable;
     
   
        this.userChargeLoader = false;
      }
    },

    async userSearch() {
     
      this.userChargeLoader = true;
      const dataSearch = await apiRequest(
        "GET",
        "admin/don?search="+this.recherche,
        undefined,


        false
      );
      if (dataSearch && dataSearch.data) {
        const donFormatTable = dataSearch.data.map((don) => {
          return {

            titreDon: don.action.titre,
            date: new Date(don.dateVirement).toLocaleString("fr-FR"),
            donateur: don.user!=null? don.user.personne.lastname + " " + don.user.personne.firstname:"",
            montant: don.fraisSocial,
          };
        });

        this.newDons = donFormatTable;
        this.userChargeLoader = false;
      }
    },

    decrement() {
      this.userChargeLoader = true;

      this.startValue -= 500;
      this.endValue -= 500;
     
      this.actif(this.startValue, this.endValue);
    },

    increment() {
      this.userChargeLoader = true;
      this.startValue += 500;
      this.endValue += 500;
     
      this.actif(this.startValue, this.endValue);
    },
    incrementToLast() {
      this.userChargeLoader = true;
      this.startValue = this.totalValue - 500;
      this.endValue = this.totalValue;
     
      this.actif(this.startValue, this.endValue);
    },
    firstVal() {
      this.userChargeLoader = true;
      this.startValue = 1;
      this.endValue = 500;
     
      this.actif(this.startValue, this.endValue);
    },

    
positionAdd() {
      Vue.swal({
        positionAdd: "center-end",
        icon: "success",
        title: "Ajout éffectué avec succès",
        showConfirmButton: false,
        timer: 3000,
      });
    },
    position() {
      Vue.swal({
        position: "center-end",
        icon: "success",
        title: "Mise à jour effectuée avec succès!!",
        showConfirmButton: false,
        timer: 3000,
      });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="containerDot" v-if="dons.length == 0">
            <span class="loading">Chargement en cours... </span>
         
          </div>
          <div
            class="d-flex align-items-center otherThing"
            v-if="userChargeLoader === true"
          >
            <span class="loading">Chargement en cours...</span>
            <div aria-hidden="true"></div>
          </div>
          
          <div class="card-body">
           
            <div
              class="row d-flex justify-content-between  p-2"
            >
            <div class="card-title">
              <b>Don reçu</b>
            </div>

           <!--    
              <transition mode="out-in">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
               
                    <li>
                      <span class="interval"> {{ startValue }}</span> -
                      <span class="interval">{{ endValue }}</span> sur
                      {{ totalValue }}
                    </li>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <li>
                      <button
                        class="myBouton"
                        @click="firstVal()"
                        :disabled="startValue == 1"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Prem"
                      >
                        <ion-icon name="play-skip-back-outline"></ion-icon>
                      </button>
                      <button
                        type="button"
                        class="myBouton"
                        @click="decrement()"
                        :disabled="endValue == 500"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Préc"
                      >
                        <ion-icon name="chevron-back-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="increment()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Suiv"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="incrementToLast()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Dern"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="play-skip-forward-outline"></ion-icon>
                      </button>
                    </li>
                  </ul>
                </div>
              </transition> -->
            </div>
            <form>
              <b-tabs nav-class="nav-tabs-custom">
                <b-tab title-link-class="p-3">
                  <template v-slot:title>
                    <a class="font-weight-bold active">Liste des dons</a>
                  </template>
                  <div class="row  d-flex justify-content-between align-items-center mt-4">
                    <div class="p-3">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          Afficher&nbsp;
                          <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                          ></b-form-select
                          >&nbsp;entrées
                        </label>
                      </div>
                    </div>
                    <!-- Search -->
                    <div class="inputCol p-3">
                    <div class="input">
                      <input
                        type="search"
                        id="serchInput"
                        placeholder="Rechercher..."
                        v-model="filter"
                      />
                    </div>
                  </div>
              
                  </div>
            
                  <div class="table-responsive">
                    <b-table
                      class="table-centered"
                      :items="newDons"
                      :fields="fields"
                      responsive="sm"
                      :per-page="perPage"
                      :current-page="currentPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                    >

                    <template v-slot:cell(donateur)="row">

                      <div @click="goToUserDetail(row.item.id)">
                        <span>
                        {{ row.item.donateur }}
                      </span>
                      <i  class="fas fa-eye"></i>

                      </div>
                     

                    </template>
                      <template v-slot:cell(action)="row">
                        <a
                          href="javascript:void(0);"
                          class="text-primary"
                          v-b-tooltip.hover
                          @click="editer(row.item)"
                          title="Editer"
                        >
                          <i class="mdi mdi-pencil font-size-18"></i>
                        </a>
                        <a
                          href="javascript:void(0);"
                          class="text-success"
                          v-b-tooltip.hover
                          @click="desactiver(row.item)"
                          title="Désactiver"
                        >
                          <i class="mdi mdi-close font-size-18"></i>
                        </a>
                        <a
                          href="javascript:void(0);"
                          class="text-danger"
                          @click="supprimer(row.item)"
                          v-b-tooltip.hover
                          title="supprimer"
                        >
                          <i class="mdi mdi-trash-can font-size-18"></i>
                        </a>
                      </template>
                    </b-table>
                  </div>
                  <div class="row">
              <div class="col">
         <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      :aria-controls="dons"
                      :rows="dons"
                    ></b-pagination>
                  </ul>
                </div>
             <!--    <transition mode="out-in">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                 
                      <li>
                        <span class="interval"> {{ startValue }}</span> -
                        <span class="interval">{{ endValue }}</span> sur
                        {{ totalValue }}
                      </li>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <li>
                        <button
                          class="myBouton"
                          @click="firstVal()"
                          :disabled="startValue == 1"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Prem"
                        >
                          <ion-icon name="play-skip-back-outline"></ion-icon>
                        </button>
                        <button
                          type="button"
                          class="myBouton"
                          @click="decrement()"
                          :disabled="endValue == 500"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Préc"
                        >
                          <ion-icon name="chevron-back-outline"></ion-icon>
                        </button>
                        <button
                          class="myBouton"
                          @click="increment()"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Suiv"
                          :disabled="endValue >= totalValue"
                        >
                          <ion-icon name="chevron-forward-outline"></ion-icon>
                        </button>
                        <button
                          class="myBouton"
                          @click="incrementToLast()"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Dern"
                          :disabled="endValue >= totalValue"
                        >
                          <ion-icon name="play-skip-forward-outline"></ion-icon>
                        </button>
                      </li>
                    </ul>
                  </div>
                </transition> -->
              </div>
            </div>
                </b-tab>
              </b-tabs>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
#showPassword {
  width: 35px;
  height: 35px;
}


thead {
  background: #3AAA35;
}thead div{
  color:#fff;
}

.input {
  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 35px;
  bottom: 0;
  flex: 60%;
margin-bottom: 5px;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}
.input input:focus {
  border: 3px solid #3aaa35;
}
.inputCol {
  position: relative;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}




ion-icon {
  cursor: pointer;
}

.myBouton {
  background: transparent;
  text-align: center;
  outline: none;
  border: none;
}

.containerDot {
  z-index: 100;
}

.paging_simple_numbers {
  background: #3AAA35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
} .paging_simple_numbers ul li{
  color:white!important;
}


.paging_simple_numbers ul li button{
  color:#fff!important;
}


.loading {
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
}

.loading {
  font-weight: bold;
  font-size: 1.001rem;
  width: 100%;
  white-space: nowrap;
  color: hsl(0, 0%, 12%);
  text-align: center;
  display: inline-block;
  margin: 0 auto !important;
}
.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;

}
.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: 8%;
  left: 0;
  right: 0;
  margin: 0 auto;
}
</style>
